import React, { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import LogsTable from './components/LogsTable';

import {
  fetchCheckLogs
} from 'actions';

class LogsTableContainer extends Component {
  constructor(props) {
    super(props);
    const {
      page,
      checkId
    } = props;

    this.state = {
      currentPage: page,
      logsLoading: true,
      checkId: checkId,
      perPage: 10
    };
  }

  componentDidMount() {
    const {
      checkId,
      perPage
    } = this.state
    const {
      page,
      dataStore,
      sessionStore,
      fetchCheckLogs
    } = this.props;
    const {
      token
    } = sessionStore;

    const fetchOptions = {
      checkId: checkId,
      perPage: perPage
    };

    let cachedPages = dataStore.logs.byPage;

    if (checkId) {
      cachedPages = dataStore.selectedCheck.logs.byPage;
    }

    if (cachedPages[`page${page}`]) {
      this.setState(() => ({
        logsLoading: false
      }));
    } else {
      fetchCheckLogs(token, fetchOptions)
        .then((response) => {
          console.log('[LogsTableContainer.js][fetchCheckLogs]');
          this.setState(() => ({
            logsLoading: false
          }));
          console.warn(response);
        })
        .catch((err) => {
          console.err('[LogsTableContainer.js][fetchCheckLogs]: ', err);
        });
    }
  }

  getCheckDetailsLink = (id) => {
    return `/checks/${id}/details`;
  }

  handleChangePage = (_event, pageIndex) => {
    const {
      checkId,
      perPage
    } = this.state;
    const {
      dataStore,
      sessionStore,
      fetchCheckLogs
    } = this.props;
    const {
      token
    } = sessionStore;

    let cachedPage = dataStore.logs.byPage;

    if (checkId) {
      cachedPage = dataStore.selectedCheck.logs.byPage;
    }

    const page = pageIndex + 1;
    const fetchOptions = {
      checkId: checkId,
      page: page,
      perPage: perPage
    };

    if (!cachedPage[`page${page}`]) {
      this.setState(() => ({
        logsLoading: true
      }));

      fetchCheckLogs(token, fetchOptions)
        .then(() => {
          this.setState(() => ({
            currentPage: page,
            logsLoading: false
          }));
        })
    } else {
      this.setState(() => ({
        currentPage: page
      }));
    }
  }

  render() {
    const {
      currentPage,
      logsLoading,
      checkId,
      perPage
    } = this.state;
    const {
      dataStore
    } = this.props;

    let currentPageLogs = dataStore.logs.byPage[`page${currentPage}`];
    let totalLogsCount = dataStore.logs.count;

    if (checkId) {
      currentPageLogs = dataStore.selectedCheck.logs.byPage[`page${currentPage}`];
      totalLogsCount = dataStore.selectedCheck.logs.count;
    }

    return (
      <LogsTable
        logs={currentPageLogs || []}
        currentPage={currentPage}
        rowsPerPage={perPage}
        handleChangePage={this.handleChangePage}
        totalLogsCount={totalLogsCount}
        loading={logsLoading}
        showCheckName={!checkId}
      />
    );
  }
}

LogsTableContainer.propTypes = {
  page: PropTypes.number.isRequired
};

LogsTableContainer.defaultProps = {
  page: 1
};

const mapStateToProps = (state) => {
  return {
    dataStore: state.dataStore,
    sessionStore: state.sessionStore,
    uiStore: state.uiStore
  };
};

export default connect(mapStateToProps, {
  fetchCheckLogs: fetchCheckLogs,
})(LogsTableContainer);

import * as actionTypes from 'actions';

// TODO: REMOVE
console.log('[uiReducer.js]');

//? NOTE: there's a lot going on in terms of fetch states, could potentially
//? (cont.) optimize, e.g. string values (userDataState: 'LOADING' / 'SUCCESS'
//? (cont.)  etc., but implementation may change so this is sufficient for
//? (cont.) now, ideally we have these all under "user data", but that Promise
//? (cont.) was getting too heavy and slower calls were blocking, hence these
const initialState = {
  errorMessage: '',
  successMessage: '',

  // user data (connections & checks)
  userDataLoading: false,
  userDataSuccess: false,
  userDataFailure: false,

  // user incidents
  userIncidentsLoading: false,
  userIncidentsSuccess: false,
  userIncidentsFailure: false,

  // user logs
  userLogsLoading: false,
  userLogsSuccess: false,
  userLogsFailure: false
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SUCCESS_SNACKBAR_MESSAGE: {
      return {
        ...state,
        successMessage: action.payload
      };
    }

    case actionTypes.SET_ERROR_SNACKBAR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.payload
      };
    }

    case actionTypes.SET_USER_DATA_FETCH_STATUS_LOADING: {
      return {
        ...state,
        userDataLoading: true
      };
    }

    case actionTypes.SET_USER_DATA_FETCH_STATUS_SUCCESS: {
      return {
        ...state,
        userDataLoading: false,
        userDataSuccess: true
      };
    }

    case actionTypes.SET_USER_DATA_FETCH_STATUS_FAILURE: {
      return {
        ...state,
        userDataLoading: false,
        userDataFailure: true
      };
    }

    case actionTypes.SET_USER_INCIDENTS_FETCH_STATUS_LOADING: {
      console.log('SET_USER_INCIDENTS_FETCH_STATUS_LOADING');
      return {
        ...state,
        userIncidentsLoading: true
      };
    }

    case actionTypes.SET_USER_INCIDENTS_FETCH_STATUS_SUCCESS: {
      return {
        ...state,
        userIncidentsLoading: false,
        userIncidentsSuccess: true
      };
    }

    case actionTypes.SET_USER_INCIDENTS_FETCH_STATUS_FAILURE: {
      return {
        ...state,
        userIncidentsLoading: false,
        userIncidentsFailure: true
      };
    }

    case actionTypes.SET_USER_LOGS_FETCH_STATUS_LOADING: {
      return {
        ...state,
        userLogsLoading: true
      };
    }

    case actionTypes.SET_USER_LOGS_FETCH_STATUS_SUCCESS: {
      return {
        ...state,
        userLogsLoading: false,
        userLogsSuccess: true
      };
    }

    case actionTypes.SET_USER_LOGS_FETCH_STATUS_FAILURE: {
      return {
        ...state,
        userLogsLoading: false,
        userLogsFailure: true
      };
    }

    default: {
      return state;
    }
  }
};

export default uiReducer;

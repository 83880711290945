export const doesErrorExist = (data) => {
  if (data.status_code && data.status_code !== 200) {
    return true;
  }

  if (data.status && data.status.toLowerCase() !== 'success') {
    return true;
  }
};

export const generateErrorMessage = (errorObject) => {
    // TODO: parse err message for better logging:
    // TODO: i.e. fields so far: error / errors / status / description
    let errorMessage = errorObject.errors;
    let errorDescription;
    let fullErrorMessage;

    if (errorObject.error) {
      errorMessage = errorObject.error;
      errorDescription = errorObject.description;

      fullErrorMessage = `ERR: ${errorMessage} DESC: ${errorDescription}`;
    } else if (errorObject.errors && errorObject.errors.message) {
      fullErrorMessage = `ERR: ${errorObject.errors.message}`;
    } else {
      fullErrorMessage = `ERR: ${errorMessage}`;
    }

    return fullErrorMessage;
};

import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import dataReducer from './dataReducer';
import uiReducer from './uiReducer';

const rootReducer = combineReducers({
  sessionStore: sessionReducer,
  dataStore: dataReducer,
  uiStore: uiReducer
});

export default rootReducer;

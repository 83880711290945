import {
  API_BASE_URL
} from './shared';

export const getUserDetails = (token) => {
  // TODO: try bad token to ensure err's caught
  console.log(token);

  return fetch(`${API_BASE_URL}/me`, {
    headers: {
      'Authorization': `JWT ${token}`
    }
  })
    .then((res) => res.json());
  // TODO: err handling
};

export const registerUser = (userDetails) => {
  const postBody = {
      organization: userDetails.organization,
      invite_code: userDetails.inviteCode,
      password: userDetails.password,
      first_name: userDetails.firstName,
      last_name:userDetails.lastName,
      email: userDetails.email,
  };

  return fetch(`${API_BASE_URL}/signup`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(postBody)
  })
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
}

export const updateUser = (token, userDetails) => {
  const postBody = {
      first_name: userDetails.firstName,
      last_name:userDetails.lastName,
      email: userDetails.email,
      password: userDetails.password
  };

  return fetch(`${API_BASE_URL}/user`, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postBody)
  })
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
}

export const getAuthToken = (email, password) => {
  const postBody = {
    email: email,
    password: password
  };

  return fetch(`${API_BASE_URL}/auth`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(postBody)
  })
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
};

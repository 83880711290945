import React, { Component } from 'react';
import {connect} from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import {
  AuthGuard,
  DynamicSnackbar
} from 'components';
import Dashboard from '../Dashboard';
import { fetchUserDetails } from 'actions';

class AuthenticatedDashboard extends Component {
  state = {
    jsonWebToken: localStorage.getItem('JWT__TidyData')
  };

  componentDidMount() {
    const {
      session,
      fetchUserDetails,
    } = this.props;
    const {
      jsonWebToken
    } = this.state;

    if (!session.loggedIn && jsonWebToken) {
      fetchUserDetails(jsonWebToken);
    }
  }

  render() {
    const {
      session
    } = this.props;
    const {
      jsonWebToken
    } = this.state;

    let userRoles = [];
    userRoles.push(session.user.role);

    if (!session.loggedIn && jsonWebToken) {
      return (
        <LinearProgress />
      );
    } else {
      return  (
        <AuthGuard roles={userRoles}>
          <Dashboard route={this.props.route} />
          <DynamicSnackbar />
        </AuthGuard>
      );
    }  
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.sessionStore
  };
};

export default connect(mapStateToProps, {
  fetchUserDetails: fetchUserDetails
})(AuthenticatedDashboard);

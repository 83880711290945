import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ListAltIcon from '@material-ui/icons/ListAlt';
import WarningIcon from '@material-ui/icons/Warning';
import LinkIcon from '@material-ui/icons/Link';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

export default [
  {
    title: 'key--main-navigation',
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: DashboardIcon
      },
      {
        title: 'Data Sources',
        href: '/data-sources',
        icon: SettingsInputComponentIcon,
        showActiveData: 'connections'
      },
      {
        title: 'Checks',
        href: '/checks',
        icon: DoneAllIcon,
        showActiveData: 'checks'
      },
      {
        title: 'Logs',
        href: '/logs',
        icon: ListAltIcon,
      },
      {
        title: 'Incidents',
        href: '/incidents',
        icon: WarningIcon,
      },
      {
        title: 'Integrations',
        href: '/integrations',
        icon: LinkIcon,
      },
      {
        title: 'Account',
        href: '/account/general',
        icon: SettingsIcon,
      }
    ]
  }
];

import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography
} from '@material-ui/core';

const Header = (props) => {
  const {
    children,
    title,
    subtitle
  } = props;

  return (
    <Grid
      alignItems="flex-end"
      container
      justify="space-between"
      spacing={3}
    >
      <Grid item>
        <Typography
          component="h1"
          variant="h3"
        >
          {title}
        </Typography>
        <Typography
          gutterBottom
          variant="subtitle2"
        >
          &mdash; {subtitle}
        </Typography>
      </Grid>
      <Grid item>
        {children}
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default Header;

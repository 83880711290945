import React, { useEffect, useRef } from 'react';
import c3 from 'c3';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';

import 'c3/c3.min.css';
import './c3-overrides.css';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  },
  chartWrapper: {
    height: 375,
    minWidth: 500
  }
}));

function LogsChartV2({ name, data }) {
  const chartEl = useRef(null);
  const classes = useStyles();

  let labelTimestamps = [...data.labels];

  useEffect(() => {
    function getColumns() {
      let primaryChartDataSet;
      let secondaryChartDataSet = [];

      if (data.data.secondaryDataSet.length > 0) {
        primaryChartDataSet = ['Table A', ...data.data.primaryDataSet];
        secondaryChartDataSet = ['Table B', ...data.data.secondaryDataSet];

        return [primaryChartDataSet, secondaryChartDataSet];
      } else {
        primaryChartDataSet = [name, ...data.data.primaryDataSet];

        return [primaryChartDataSet];
      }
    }

    c3.generate({
      bindto: chartEl.current,
      data: {
        columns: getColumns()
      },
      grid: {
        x: {
          show: true
        },
        y: {
          show: true
        }
      },
      axis: {
        x: {
          type: 'category',
          categories: labelTimestamps
        }
      },
      legend: {
        show: false
      }
    });
  }, [name, data, labelTimestamps]);

  return (
    <Card
      className={classes.root}
    >
      <CardHeader
        title="Logs Chart"
      />
      <Divider />
      <CardContent className={classes.content}>
        <div
          className={classes.chartWrapper}
        >
          <div ref={chartEl}></div>
        </div>
      </CardContent>
    </Card>
  );
}

export default LogsChartV2;

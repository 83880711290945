import {
  API_BASE_URL,
  SNOWFLAKE_URL
} from './shared';

import {
  doesErrorExist
} from 'actions/utilities/errorHandling';

export const getConnectionsList = (token) => {
  // TODO: try bad token to ensure err's caught
  console.log(token);

  return fetch(`${API_BASE_URL}/connections`, {
    headers: {
      'Authorization': `JWT ${token}`
    }
  });
};

export const getConnection = (token, id) => {
  return fetch(`${API_BASE_URL}/connections?id=${id}`, {
    headers: {
      'Authorization': `JWT ${token}`
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (doesErrorExist(data)) {
        throw data;
      }

      return mungeToConnectionForm(data.connection);
    })
    .catch((err) => {
      throw err;
    });
};

// TODO: note shared code between this and create, better to leave for now as API
// TODO: (cont.) might change, not worth refactoring into one method for MVP, at
// TODO: (cont.) best, combine some shared code so method names remain different
export const updateConnection = (token, connectionData, id) => {
  // TODO: switch to debugger level logging / add global flag
  console.group('createConnection()');
  console.log('connectionData', connectionData);
  const transformedData = transformConnectionDataForCreate(connectionData);

  console.log('transformedData', transformedData);
  console.groupEnd();

  // the difference for update!
  transformedData.id = Number(id);

  return fetch(`${API_BASE_URL}/connections`, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(transformedData)
  })
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
};

export const createConnection = (token, connectionData) => {
  // TODO: switch to debugger level logging / add global flag
  console.group('createConnection()');
  console.log('connectionData', connectionData);
  const transformedData = transformConnectionDataForCreate(connectionData);

  console.log('transformedData', transformedData);
  console.groupEnd();

  return fetch(`${API_BASE_URL}/connections`, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(transformedData)
  })
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
};

export const verifyConnection = (token, connectionData) => {
  // TODO: switch to debugger level logging / add global flag
  console.group('verifyConnection()');
  console.log('connectionData', connectionData);
  const transformedData = transformConnectionDataForVerify(connectionData);

  console.log('transformedData', transformedData);
  console.groupEnd();

  return fetch(`${API_BASE_URL}/verify_connection`, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(transformedData)
  })
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
};

// TODO: import from helper
function transformConnectionDataForVerify(connectionData) {
  const transformed = Object.assign({}, connectionData);

  transformed.type_id = Number(connectionData.source);
  transformed.user = connectionData.username;
  transformed.connection_method = connectionData.connectionType;
  transformed.port = Number(connectionData.port);

  if (transformed.type_id === 1) {
    transformed.account = connectionData.accountName;
    transformed.host = `${connectionData.accountName}.${SNOWFLAKE_URL}`;
    transformed.port = 443;
  } else {
    delete transformed.warehouse;
  }

  if (transformed.connectionType === 'tunnel') {
    transformed.tunnel_host = connectionData.tunnelHost;
    transformed.tunnel_port = Number(connectionData.tunnelPort);
    transformed.tunnel_username = connectionData.tunnelUsername;
    transformed.tunnel_private_key = connectionData.tunnelPrivateKey;
  }

  delete transformed.name;
  delete transformed.username;

  delete transformed.accountName;
  delete transformed.connectionType;
  delete transformed.source;

  delete transformed.tunnelHost;
  delete transformed.tunnelPort;
  delete transformed.tunnelUsername;
  delete transformed.tunnelPrivateKey;

  return transformed;
}

// TODO: import from helper
function transformConnectionDataForCreate(connectionData) {
  const transformed = Object.assign({}, connectionData);

  transformed.type_id = Number(connectionData.source);
  transformed.passhash = connectionData.password;
  transformed.connection_method = connectionData.connectionType;
  transformed.port = Number(connectionData.port)

  if (transformed.type_id === 1) {
    transformed.account = connectionData.accountName;
    transformed.host = `${connectionData.accountName}.${SNOWFLAKE_URL}`;
    transformed.port = 443;
  } else {
    delete transformed.warehouse;
  }

  if (transformed.connectionType === 'tunnel') {
    transformed.tunnel_host = connectionData.tunnelHost;
    transformed.tunnel_port = Number(connectionData.tunnelPort);
    transformed.tunnel_username = connectionData.tunnelUsername;
    transformed.tunnel_private_key = connectionData.tunnelPrivateKey;
  }

  delete transformed.password;

  delete transformed.accountName;
  delete transformed.connectionType;
  delete transformed.source;

  delete transformed.tunnelHost;
  delete transformed.tunnelPort;
  delete transformed.tunnelUsername;
  delete transformed.tunnelPrivateKey;

  return transformed;
}

// TODO: import from helper
function mungeToConnectionForm(rawConnectionData) {
  const munged = {};

  munged.id = rawConnectionData.id;
  munged.source = rawConnectionData.type.id;
  munged.name = rawConnectionData.name;
  munged.database = rawConnectionData.database;
  munged.username = rawConnectionData.username;
  munged.password = '';
  munged.connectionType = rawConnectionData.connection_method;

  if (munged.connectionType === 'tunnel') {
    munged.tunnelHost = rawConnectionData.tunnel_host;
    munged.tunnelPort = rawConnectionData.tunnel_port;
    munged.tunnelUsername = rawConnectionData.tunnel_username;
    munged.tunnelPrivateKey = rawConnectionData.tunnel_private_key;
  }

  if (munged.source === 1) {
    munged.accountName = rawConnectionData.account_name;
    munged.warehouse = rawConnectionData.warehouse
  } else {
    munged.host = rawConnectionData.host;
    munged.port = rawConnectionData.port;
  }

  return munged;
}

export const updateConnectionStatus = (token, id, status) => {
  // TODO: switch to debugger level logging / add global flag
  console.group('updateConnectionStatus()');
  console.log(`Setting connection: ${id} to ${status}`);
  console.groupEnd();

  const postBody = {
    id: id,
    status: status
  };

  return fetch(`${API_BASE_URL}/connections`, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(postBody)
  })
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
};

// TODO: mock service for data needed for forms
// TODO: not sure where this'll ultimately be

// TODO: actual one likely will need token for Auth header
export const getLookupTablesData = () => {
  const checkTypes = [
    {
      id: 1,
      name: 'Count',
      status: 'active'
    },
    {
      id: 5,
      name: 'Equal Expression',
      status: 'active'
    },
    {
      id: 3,
      name: 'Threshold',
      status: 'active'
    },
    {
      id: 4,
      name: 'Assert',
      status: 'active'
    }
  ];
  const connectionTypes = [
    {
      id: 1,
      name: 'Snowflake',
      status: 'active',
      port: 443
    },
    {
      id: 2,
      name: 'MySQL',
      status: 'active',
      port: 3306
    },
    {
      id: 3,
      name: 'PostgreSQL',
      status: 'active',
      port: 5432
    },
    {
      id: 4,
      name: 'RedShift',
      status: 'active',
      port: 5439
    },
    {
      id: 5,
      name: 'MongoDB',
      status: 'active',
      port: 27017
    }
  ];
  const notificationTypes = [
    {
      id: 1,
      name: 'Email',
      status: 'active'
    }, {
      id: 2,
      name: 'Slack',
      status: 'active'
    }, {
      id: 3,
      name: 'Webhook',
      status: 'active'
    }, {
      id: 4,
      name: 'PagerDuty',
      status: 'active'
    }
  ];

  const returnBody = {
    checkTypes: checkTypes,
    connectionTypes: connectionTypes,
    notificationTypes: notificationTypes
  };

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(returnBody);
    }, 400);
  });
};

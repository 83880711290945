import {
  getUserDetails,
  getAuthToken
} from 'lib/services/userService';

import {
  getLookupTablesData
} from 'lib/services/lookupService';

import {
  fetchUserDataStore
} from 'actions';

import {
  doesErrorExist
} from './utilities/errorHandling';

export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const LOAD_USER = 'LOAD_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const LOGIN_ERROR_MESSAGE = 'LOGIN_ERROR_MESSAGE';
export const CLEAR_LOGIN_ERROR_MESSAGE = 'CLEAR_LOGIN_ERROR_MESSAGE';
export const LOGIN_FORM_SUBMITTING = 'LOGIN_FORM_SUBMITTING';
export const TOGGLE_SLACK_INTEGRATION = 'TOGGLE_SLACK_INTEGRATION';
export const TOGGLE_PAGERDUTY_INTEGRATION = 'TOGGLE_PAGERDUTY_INTEGRATION';

// TODO: maybe move to another store, uiStore: ?
export const SET_LOOKUP_OPTIONS = 'SET_LOOKUP_OPTIONS';

export const loadUser = (userData) => (
  {
    type: LOAD_USER,
    payload: userData
  }
);

export const setToken = (token) => (
  {
    type: SET_TOKEN,
    payload: token
  }
);

export const loginErrorMessage = (message) => (
  {
    type: LOGIN_ERROR_MESSAGE,
    payload: message
  }
);

export const clearLoginErrorMessage = () => (
  {
    type: CLEAR_LOGIN_ERROR_MESSAGE
  }
);

export const loginFormSubmitting = (statusBool) => (
  {
    type: LOGIN_FORM_SUBMITTING,
    payload: statusBool
  }
);

export const toggleSlackIntegration = (integrations) => (
  {
    type: TOGGLE_SLACK_INTEGRATION,
    payload: integrations
  }
);

export const togglePagerdutyIntegration = (integrations) => (
  {
    type: TOGGLE_PAGERDUTY_INTEGRATION,
    payload: integrations
  }
);

export const sessionLogout = () => (
  {
    type: SESSION_LOGOUT
  }
);

// TODO: maybe move to another store, uiStore: ?
export const setLookupOptions = (lookupObject) => (
  {
    type: SET_LOOKUP_OPTIONS,
    payload: lookupObject
  }
);

// TODO: maybe move to another store, uiStore: ?
export const fetchLookupOptions = () => {
  return (dispatch) => {
    getLookupTablesData()
      .then((data) => {
        dispatch(setLookupOptions(data));
      });
  }
}

export const logOutUser = (router) => {
  return (dispatch) => {
    localStorage.removeItem('JWT__TidyData');
    dispatch(sessionLogout());
    router.history.push('/auth/login');
  };
}

export const fetchUserDetails = (token) => {
  return (dispatch) => {
    getUserDetails(token)
      .then(
        (data) => {
          // TODO: need to standardize responses
          if (doesErrorExist(data)) {
            throw data;
          }

          // TODO: could probably combine w/ loadUser
          // TODO: perhaps compose better? (see: https://github.com/reduxjs/redux-thunk#composition)
          dispatch(setToken(token));
          dispatch(loadUser(data.user));
          dispatch(fetchUserDataStore(token));
          // TODO: likely will need token
          dispatch(fetchLookupOptions());
        },
        (err) => {
          console.error('[fetchUserDetails][getUserDetails]:', err);
        }
      )
      .catch(({ error, description }) => {
        console.error(`ERR: ${error}, DESC: ${description}`);

        // TODO: add better err handling for bad token
        if (description.toLowerCase() === 'user does not exist') {
          localStorage.removeItem('JWT__TidyData');
          window.location = '/auth/login';
        }
      });
  }
};

export const logInUser = ({ email, password }, router) => {
  return (dispatch) => {
    dispatch(loginFormSubmitting(true));

    getAuthToken(email, password)
      .then(
        (data) => {
          // TODO: need to standardize responses
          if (doesErrorExist(data)) {
            throw data;
          }

          localStorage.setItem('JWT__TidyData', data.access_token);
          router.history.push('/');
        },
        (err) => {
          console.error('[logInUser][getAuthToken]:', err);
        }
      )
      .catch(({ error, description }) => {
        console.error(`ERR: ${error}, DESC: ${description}`);
        dispatch(loginFormSubmitting(false));
        dispatch(loginErrorMessage(`Error: ${error} - ${description}`));
      });
  }
};

// TODO: potentially revisit if invalid JWT's get saved?
// function handleError(errorMessage, description) {
//   errorMessage = errorMessage.toUpperCase();
//   description = description.toUpperCase();

//   switch (errorMessage) {
//     case 'INVALID TOKEN':
//       // an invalid token was somehow set...
//       localStorage.removeItem('JWT__TidyData');
//       redirectHome();
//       break;
//     case 'BAD REQUEST':
//       if (description === 'INVALID CREDENTIALS')
//     default:
//       break;
//   }
// }

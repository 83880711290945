import React, { Fragment, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  LinearProgress,
  Snackbar,
  SnackbarContent
} from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { Topbar } from './components';

import { clearLoginErrorMessage } from 'actions';

const useStyles = makeStyles(theme => ({
  content: {
    height: '100%',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: 20
  }
}));

const Auth = props => {
  const { route } = props;

  const classes = useStyles();
  const session = useSelector(state => state.sessionStore);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(clearLoginErrorMessage());
  }

  return (
    <Fragment>
      <Topbar />
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>

        {/* TODO: Move into separate component */}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          autoHideDuration={6000}
          // TODO: use better boolean check...
          open={session.loginErrorMessage.length > 0}
        >
          <SnackbarContent
            className={classes.error}
            message={
              <span className={classes.message}>
                <ErrorIcon className={classes.icon} />
                {session.loginErrorMessage}
              </span>
            }
            variant="h6"
            action={[
              <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]}
          />
        </Snackbar>

      </main>
    </Fragment>
  );
};

Auth.propTypes = {
  route: PropTypes.object
};

export default Auth;

import moment from 'moment';
import { cloneDeep } from 'lodash';

export const connectionsTransformer = ({
  connectionsData,
  checksList
}) => {
  const {
    connections: connectionsList,
    check_count: checkCount
  } = connectionsData;

  let connections = connectionsList.map((connection) => {
    return {
      id: connection.id,
      typeId: connection.type.id,
      type: connection.type.name,
      name: connection.name,
      status: connection.status,
      createdByEmail: connection.user.email,
      updatedAt: connection.updated_at,
      checks: getConnectionChecks(connection.id, checksList),
      healthPercentage: getConnectionHealth(connection.id, checksList),
      //? NOTE: could just parse above where needed too
      totalActiveChecks: getConnectionsActiveChecks(connection.id, checkCount),
      totalPausedChecks: getConnectionsPausedChecks(connection.id, checkCount)
    };
  });
  
  return connections;
};

function getConnectionsActiveChecks(id, checkCount) {
  const selectedConnectionArray = checkCount.filter((countObj) => {
    return countObj.connection_id === id && countObj.status === 'active';
  });

  if (selectedConnectionArray.length < 1) {
    return 0;
  }

  return selectedConnectionArray[0].count;
}

function getConnectionsPausedChecks(id, checkCount) {
  const selectedConnectionArray = checkCount.filter((countObj) => {
    return countObj.connection_id === id && countObj.status === 'paused';
  });

  if (selectedConnectionArray.length < 1) {
    return 0;
  }

  return selectedConnectionArray[0].count;
}

export const checksTransformer = ({
  checksList
}) => {
  let checks = checksList.map((check) => {
    return {
      id: check.id,
      // TODO: prob need err checking to confirm these, perhaps implementing
      // TODO: (cont.) TC-39 null coalescing operator from proposal?
      name: check.name,
      type: check.type.name,
      status: check.status,
      frequency: check.frequency,
      frequencyMetric: check.frequency_metric,
      threshold: check.threshold,
      notificationMessage: getNotificationMessage(check.notifications),
      notificationList: getNotificationList(check.notifications),
      lastIncidentAt: getFormattedDate(check.last_incident),
      isPassing: isLastIncidentOverHoursOld(1, check.last_incident),
      healthPercentage: getHealthPercentage(check.failure_count, check.success_count),
      connectionId: check.connection.id,
      connectionName: getConnectionName(check.connection.name, check.connection_details),
      connectionTypeId: check.connection.type.id,
      connectionType: check.connection.type.name,
      totalIncidents: check.failure_count,
      totalLogs: check.success_count,
      query: check.query
    };
  });

  return checks;
};

//! TODO: move to helper once format is finalized
function getHealthPercentage(failures, successes) {
  let healthPercentage = Math.floor((successes / (successes + failures)) * 100);

  if (isNaN(healthPercentage)) {
    healthPercentage = 0;
  }

  return healthPercentage;
}

//! TODO: move to helper once format is finalized
function getFormattedDate(timestamp) {
  if (!timestamp) {
    return null;
  }

  //? TODO: determine format for app and use consistently
  return moment(timestamp).format('ddd, D MMM YYYY - h:mma');
}

//! TODO: move to helper once format is finalized
function isLastIncidentOverHoursOld(hours, lastIncidentTimestamp) {
  if (!lastIncidentTimestamp) {
    return true;
  }

  if (!hours) {
    // default to 6 hours
    hours = 6
  }

  var oneDayAgo = moment().subtract(hours, 'hours');

  return moment(lastIncidentTimestamp).isBefore(oneDayAgo);
}

//! TODO: move to helper once format is finalized
function getNotificationList(notifications) {
  const formattedNotifications = notifications.map((notification) => {
    switch (notification.type.id) {
      // Email
      case 1:
        return {
          id: notification.id,
          typeId: notification.type.id,
          typeName: notification.type.name.toLowerCase(),
          sentTo: notification.sent_to
        };
      // Slack
      case 2:
        return {
          id: notification.id,
          typeId: notification.type.id,
          typeName: notification.type.name.toLowerCase(),
          sentTo: notification.channel
        };
      // Webhook
      case 3:
        return {
          id: notification.id,
          typeId: notification.type.id,
          typeName: notification.type.name.toLowerCase(),
          sentTo: notification.url,
          httpMethod: notification.webhook_method
        };
      // PagerDuty
      case 4:
        return {
          id: notification.id,
          //name: notification.name,
          name: notification.type.name.toLowerCase(),
          typeId: notification.type.id,
          typeName: notification.type.name.toLowerCase()
        };
      default:
        return null;
    }
  });

  return formattedNotifications;
}

export const logsTransformer = (previousLogsState, checkLogsResponse) => {
  const {
    count,
    page,
    check_logs: checkLogs
  } = checkLogsResponse;

  const newLogsState = cloneDeep(previousLogsState);

  const transformedLogs = checkLogs.map((log) => {
    return {
      id: log.id,
      date: moment(log.time).format('MM/DD/YYYY - h:mma'),
      dateRaw: log.time,
      value: log.meta.value.join(", "),
      connectionName: getConnectionName(log.check.connection.name, log.meta.connection_details),
      checkId: log.check.id,
      checkName: log.check.name,
      checkTypeName: log.check.type.name,
      query: log.meta.query
    };
  });

  newLogsState.count = count;
  newLogsState.byPage[`page${page}`] = transformedLogs;

  console.log('TRANSFORMED LOGS');

  return newLogsState;
};

export const incidentsTransformer = (previousIncidentsState, incidentsResponse) => {
  const {
    count,
    page,
    incidents
  } = incidentsResponse;

  const newIncidentsState = cloneDeep(previousIncidentsState);
  const transformedIncidents = incidents.map((incident) => {
    return {
      id: incident.id,
      checkId: incident.check.id,
      checkName: incident.check.name,
      createdAt: moment(incident.created_at).format('MM/DD/YYYY - h:mma'),
      dateRaw: incident.created_at,
      // TODO: add null checking
      notificationTypeName: incident.meta.notifications[0].type.name.toLowerCase(),
      message: getIncidentNotificationMessage(incident.meta.notifications[0]),
      additionalNotifications: formatAdditionalNotifications(incident, incident.meta.notifications)
    };
  });

  newIncidentsState.count = count;
  newIncidentsState.byPage[`page${page}`] = transformedIncidents;

  return newIncidentsState;
};

function formatAdditionalNotifications(incident, notificationsArray = []) {
  if (notificationsArray.length < 2) {
    return [];
  }

  notificationsArray = notificationsArray.slice(1);

  return notificationsArray.map((notification) => {
    return {
      id: `incidentId:${incident.id}-notificationId:${notification.id}`,
      notificationTypeName: notification.type.name.toLowerCase(),
      message: getIncidentNotificationMessage(notification),
    };
  });
}

export const integrationsTransformer = (rawIntegrations) => {
  const transformedIntegrations = rawIntegrations.map((integration) => {
    return {
      id: integration.id,
      name: integration.name,
      typeId: integration.type.id,
      status: integration.status,
      apiKey: integration.api_key,
      apiSecret: integration.api_secret
    };
  });

  //! NOTE: pushing a mock "Amazon S3" integration to demo 'coming soon'
  transformedIntegrations.push({
    id: 999,
    name: "Your Future S3 Integration",
    typeId: 2,
    status: 'unavailable'
  });

  //! NOTE: pushing a mock "Zapier" integration to demo 'coming soon'
  transformedIntegrations.push({
    id: 1337,
    name: "Your Future Zapier Integration",
    typeId: 5,
    status: 'unavailable'
  });

  return transformedIntegrations;
};

export const chartLogsTransformer = ({
  checkLogs
}) => {
  if (checkLogs.length < 1) {
    return [];
  }

  const chartLogsData = {
    typeId: checkLogs[0].check.type.id,
    labels: [],
    data: {
      primaryDataSet: [],
      secondaryDataSet: []
    }
  };

  let checkLogsSortedAndTrimmed = checkLogs.slice(0, 15).reverse();

  checkLogsSortedAndTrimmed.forEach((log) => {
    let formattedDate = moment(log.time).format('MM/DD/YY - h:mm A');

    chartLogsData.labels.push(formattedDate);

    // TODO: @DucChau we ever gonna have an array of > 2 values
    // TODO: (cont.) handling Equal Expressions in this transform
    // TODO: (cont.) can prob. optimize and have Chart handle instead
    if (log.meta.value.length > 1) {
      chartLogsData.data.primaryDataSet.push(log.meta.value[0]);
      chartLogsData.data.secondaryDataSet.push(log.meta.value[1]);
    } else {
      chartLogsData.data.primaryDataSet.push(log.meta.value[0]);
    }
  });

  return chartLogsData;
};

function getConnectionChecks(connectionId, checks) {
  return checks.filter((check) => {
    if (check.connection.id === connectionId) {
      return check;
    }

    return false;
  });
}

function getConnectionName(connectionName, connectionDetails=[]) {
  if (connectionDetails.length === 0) {
    return connectionName;
  }

  let name_list = []
  connectionDetails.forEach(function(details){
      name_list.push(details["name"]);
  });
  return name_list.join(" / ");
}

function getConnectionHealth(connectionId, checks) {
  const connectionActiveChecks = checks.filter((check) => {
    return check.connection.id === connectionId &&
      check.status === 'active';
  });

  if (connectionActiveChecks.length < 1) {
    return 0;
  }

  const activeChecksHealthValues = [];
  
  connectionActiveChecks.forEach((activeCheck) => {
    let checkHealth = calculateCheckHealth(activeCheck);

    activeChecksHealthValues.push(checkHealth);
  });

  let averageHealth = average(activeChecksHealthValues);

  averageHealth = Math.floor(averageHealth);

  if (isNaN(averageHealth)) {
    averageHealth = 0;
  }

  return averageHealth;
}

function calculateCheckHealth(check) {
  let totalCount = check.failure_count + check.success_count;

  return Math.floor((check.success_count / totalCount) * 100);
}

function average(nums) {
  return nums.reduce((a, b) => (a + b)) / nums.length;
}

function getIncidentNotificationMessage(notification) {
  switch(notification.type.id) {
    case 1:
      return `sent to ${notification.sent_to}`;
    case 2:
      return `message sent to ${notification.channel}`;
    case 3:
      return `${notification.webhook_method.toUpperCase()} to ${notification.url}`;
    case 4:
      return `message sent to ${notification.name}`;
    default:
      return `[ MISSING NOTIFICATION TYPE ]`;
  }
}

function getNotificationMessage(notifications) {
  let message = [];

  notifications.forEach((notification) => {
    switch(notification.type.id) {
      case 1:
        message.push(`${notification.type.name}: ${notification.sent_to}`);
        break;
      case 2:
        message.push(`${notification.type.name}: ${notification.channel}`);
        break;
      case 3:
        message.push(`${notification.type.name} (${notification.webhook_method}) ${notification.url}`);
        break;
      default:
        return;
    }
  });

  return message.join(", ");
}

export const findAndUpdateCheck = (checks, { id, status }) => {
  var updatedChecks = cloneDeep(checks);

  var indexToMutate = updatedChecks.findIndex((item) => {
    return item.id === id;
  });

  updatedChecks[indexToMutate].status = status;

  return updatedChecks;
};

export const findAndUpdateConnection = (connections, { id, status }) => {
  var updatedConnections = cloneDeep(connections);

  var indexToMutate = updatedConnections.findIndex((item) => {
    return item.id === id;
  });

  updatedConnections[indexToMutate].status = status;

  return updatedConnections;
};

export const findAndUpdateIntegration = (integrations, { id, status }) => {
  var updatedIntegrations = cloneDeep(integrations);

  var indexToMutate = updatedIntegrations.findIndex((item) => {
    return item.id === id;
  });

  updatedIntegrations[indexToMutate].status = status;

  return updatedIntegrations;
};

import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import {
  Button
} from '@material-ui/core';

const styles = (theme) => ({
  copyTextButton: {
    width: 200,
    margin: 16
  }
});

function copyTextToClipboard(text) {
  return navigator.clipboard.writeText(text);
}

class ButtonCopyToClipboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: props.text,
      copySuccess: false
    }
  }

  handleCopyClick = () => {
    const {
      text
    } = this.props;

    console.group('handleCopyClick()');
    console.log(text);
    console.groupEnd();

    copyTextToClipboard(text)
      .then(() => {
        this.setState(() => ({
          copySuccess: true
        }));
        console.log('[ButtonCopyToClipboard]: Copied to Clipboard!');
      }, (err) => {
        console.error('[ButtonCopyToClipboard]: Copy to Clipboard err: ', err);
      });
  };

  render() {
    const {
      classes
    } = this.props;
    const {
      copySuccess
    } = this.state;

    return (
      <Button
        className={classes.copyTextButton}
        color="primary"
        onClick={this.handleCopyClick}
        variant="contained"
      >
        {copySuccess
          ? (
              <span>Copied!</span>
            )
          : (
              <span>Copy To Clipboard</span>
            )
        }
      </Button>
    );
  }
}

export default withStyles(styles)(ButtonCopyToClipboard);

import * as actionTypes from 'actions';

// TODO: REMOVE
console.log('[sessionReducer.js]');

// const initialState = {
//   loggedIn: false,
//   user: {
//     first_name: 'FName_init',
//     last_name: 'LName_init',
//     email: 'demo@devias.io',
//     avatar: '/images/avatars/avatar_11.png',
//     bio: 'bio_init',
//     role: 'ADMIN' // ['GUEST', 'USER', 'ADMIN']
//   }
// };

const initialState = {
  loggedIn: false,
  loginErrorMessage: '',
  loginFormSubmitting: false,
  token: '',
  user: {
    firstName: 'FName_init',
    lastName: 'LName_init',
    email: 'email@default.init',
    role: '',
    organization: {
      name: 'OName_init'
    }
  },
  // TODO: maybe move to another store, uiStore: ?
  lookupOptions: {},
  // TODO: may end up being on user object
  isSlackIntegrationActive: true,
  // TODO: may end up being on user object
  isPagerdutyIntegrationActive: true
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGOUT: {
      return {
        ...initialState
      };
    }

    case actionTypes.LOAD_USER: {
      return {
        ...state,
        loggedIn: true,
        user: transformUserStateFromPayload(action.payload)
      };
    }

    case actionTypes.SET_TOKEN: {
      return {
        ...state,
        token: action.payload
      };
    }

    case actionTypes.LOGIN_ERROR_MESSAGE: {
      return {
        ...state,
        loginErrorMessage: action.payload
      };
    }

    case actionTypes.CLEAR_LOGIN_ERROR_MESSAGE: {
      return {
        ...state,
        loginErrorMessage: ''
      };
    }

    case actionTypes.LOGIN_FORM_SUBMITTING: {
      return {
        ...state,
        loginFormSubmitting: action.payload
      };
    }

    case actionTypes.TOGGLE_SLACK_INTEGRATION: {
      return {
        ...state,
        isSlackIntegrationActive: checkForSlackIntegration(action.payload)
      };
    }

    case actionTypes.TOGGLE_PAGERDUTY_INTEGRATION: {
      return {
        ...state,
        isPagerdutyIntegrationActive: checkForPagerdutyIntegration(action.payload)
      };
    }

    case actionTypes.SET_LOOKUP_OPTIONS: {
      return {
        ...state,
        lookupOptions: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

function checkForSlackIntegration(integrations) {
  const activeSlackIntegration = integrations.filter((integration) => {
    return integration.type.id === 1 && integration.status === 'active';
  });

  if (activeSlackIntegration.length > 0) {
    return true;
  } else {
    return false;
  }
}

function checkForPagerdutyIntegration(integrations) {
  const activePagerdutyIntegration = integrations.filter((integration) => {
    return integration.type.id === 4 && integration.status === 'active';
  });

  if (activePagerdutyIntegration.length > 0) {
    return true;
  } else {
    return false;
  }
}

function transformUserStateFromPayload(payloadState) {
  const updatedUserState = {};

  updatedUserState.firstName = payloadState.first_name;
  updatedUserState.lastName = payloadState.last_name;
  updatedUserState.email = payloadState.email;
  updatedUserState.organization = payloadState.organization;

  // for the account settings form init...
  updatedUserState.password = '';

  // TODO: add roles to API or use { "status": "active" }
  updatedUserState.role = 'ADMIN';

  return updatedUserState;
}

export default sessionReducer;

import palette from '../palette';

export default {
  root: {
    padding: '10px 20px',
    background: palette.primary.main
  },
  title: {
    color: palette.white
  }
};

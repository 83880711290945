import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  LinearProgress
} from '@material-ui/core';
import moment from 'moment';

import LogsChartV2 from './components/LogsChartV2';

import {
  fetchCheckLogs
} from 'actions';

function chartLogsTransformer (checkLogs = []) {
  if (checkLogs.length < 1) {
    // TODO: add default & use Object.assign
    return {
      typeId: null,
      labels: [],
      data: {
        primaryDataSet: [],
        secondaryDataSet: []
      }
    };
  }

  const chartLogsData = {
    typeId: checkLogs[0].check.type.id,
    labels: [],
    data: {
      primaryDataSet: [],
      secondaryDataSet: []
    }
  };

  let checkLogsSortedAndTrimmed = checkLogs.slice(0, 15).reverse();

  checkLogsSortedAndTrimmed.forEach((log) => {
    let formattedDate = moment(log.time).format('MM/DD/YY - h:mm A');

    chartLogsData.labels.push(formattedDate);

    // TODO: @DucChau we ever gonna have an array of > 2 values
    // TODO: (cont.) handling Equal Expressions in this transform
    // TODO: (cont.) can prob. optimize and have Chart handle instead
    if (log.meta.value.length > 1) {
      chartLogsData.data.primaryDataSet.push(log.meta.value[0]);
      chartLogsData.data.secondaryDataSet.push(log.meta.value[1]);
    } else {
      chartLogsData.data.primaryDataSet.push(log.meta.value[0]);
    }
  });

  return chartLogsData;
};

class LogsChartContainer extends Component {
  constructor(props) {
    super(props);
    const {
      checkId
    } = props;

    this.state = {
      checkId: checkId,
      logsLoading: true,
      transformedLogs: [],
      perPage: 10
    };
  }

  componentDidMount() {
    const {
      checkId,
      perPage
    } = this.state
    const {
      sessionStore,
      fetchCheckLogs
    } = this.props;
    const {
      token
    } = sessionStore;

    console.log('LogsChartContainer -- DID MOUNT');

    const fetchOptions = {
      checkId: checkId,
      perPage: perPage
    };

    fetchCheckLogs(token, fetchOptions)
      .then((response) => {
        console.log('[LogsChartContainer.js][fetchCheckLogs]');
        this.setState(() => ({
          logsLoading: false,
          transformedLogs: chartLogsTransformer(response)
        }));
        console.warn(response);
      })
      .catch((err) => {
        console.err('[LogsChartContainer.js][fetchCheckLogs]: ', err);
      });
  }

  render() {
    const {
      logsLoading,
      transformedLogs
    } = this.state;

    if (logsLoading) {
      return (
        <LinearProgress />
      );
    }

    return (
      <Fragment>
        <LogsChartV2
          name={this.props.name}
          data={transformedLogs}
        />
      </Fragment>
    );
  }
}

LogsChartContainer.propTypes = {
  checkId: PropTypes.number
};

LogsChartContainer.defaultProps = {
  checkId: null
};

const mapStateToProps = (state) => {
  return {
    dataStore: state.dataStore,
    sessionStore: state.sessionStore
  };
};

export default connect(mapStateToProps, {
  fetchCheckLogs: fetchCheckLogs,
})(LogsChartContainer);

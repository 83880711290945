import {
  API_BASE_URL
} from './shared';

export const getIntegrationsList = (token) => {
  // TODO: try bad token to ensure err's caught
  console.log(token);

  return fetch(`${API_BASE_URL}/integrations`, {
      headers: {
        'Authorization': `JWT ${token}`
      }
    })
    .then((res) => res.json());
};

export const getIntegration = (token, integrationId) => {
  return fetch(`${API_BASE_URL}/integrations?id=${integrationId}`, {
    headers: {
      'Authorization': `JWT ${token}`
    }
  });
};

export const createIntegration = (token, integrationData) => {
  console.group('createIntegration()');
  console.log('integrationData', integrationData);

  const postBody = {
    name: integrationData.name,
    type_id: integrationData.typeId,
    api_key: integrationData.apiKey,
    api_secret: integrationData.apiSecret
  }

  console.log('postBody', postBody);
  console.groupEnd();

  return fetch(`${API_BASE_URL}/integrations`, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(postBody)
  })
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
};

export const updateIntegration = (token, integrationData) => {
  console.group('updateIntegration()');
  console.log('integrationData', integrationData);

  const postBody = {
    id: integrationData.id,
    name: integrationData.name,
    type_id: integrationData.typeId,
    api_key: integrationData.apiKey,
    api_secret: integrationData.apiSecret
  }

  console.log('postBody', postBody);
  console.groupEnd();

  return fetch(`${API_BASE_URL}/integrations`, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(postBody)
  })
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
};

export const updateIntegrationStatus = (token, id, status) => {
  // TODO: switch to debugger level logging / add global flag
  console.group('updateIntegrationStatus()');
  console.log(`Setting check: ${id} to ${status}`);
  console.groupEnd();

  const postBody = {
    id: id,
    status: status
  };

  return fetch(`${API_BASE_URL}/integrations`, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(postBody)
  })
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
};

/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import AuthenticatedDashboard from './layouts/AuthenticatedDashboard';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('views/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: AuthenticatedDashboard,
    routes: [
      // Dashboard
      {
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('views/DashboardAnalytics'))
      },
      // Connections
      {
        path: '/data-sources',
        exact: true,
        component: lazy(() => import('views/Connection/ConnectionList'))
      },
      {
        path: '/data-sources/create',
        exact: true,
        component: lazy(() => import('views/Connection/ConnectionCreate'))
      },
      {
        path: '/data-sources/:id/edit',
        exact: true,
        component: lazy(() => import('views/Connection/ConnectionEdit'))
      },
      // Checks
      {
        path: '/checks',
        exact: true,
        component: lazy(() => import('views/Check/CheckList'))
      },
      {
        path: '/checks/create',
        exact: true,
        component: lazy(() => import('views/Check/CheckCreate'))
      },
      {
        path: '/checks/:id/edit',
        exact: true,
        component: lazy(() => import('views/Check/CheckEdit'))
      },
      {
        path: '/checks/:id/details',
        exact: true,
        component: lazy(() => import('views/Check/CheckDetails'))
      },
      // Logs
      {
        path: '/logs',
        exact: true,
        component: lazy(() => import('views/Log/LogList'))
      },
      // Incidents
      {
        path: '/incidents',
        exact: true,
        component: lazy(() => import('views/Incident/IncidentList'))
      },
      // Integrations
      {
        path: '/integrations',
        exact: true,
        component: lazy(() => import('views/Integration/IntegrationList'))
      },
      // Account
      {
        path: '/account/:tab',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;

import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  SuccessSnackbar,
  ErrorSnackbar
} from './components';

import {
  setSuccessSnackbarMessage,
  setErrorSnackbarMessage
} from 'actions';

const DynamicSnackbar = (props) => {
  const ui = useSelector(state => state.uiStore);
  const dispatch = useDispatch();

  const handleSuccessClose = () => {
    dispatch(setSuccessSnackbarMessage(''));
  }

  const handleErrorClose = () => {
    dispatch(setErrorSnackbarMessage(''));
  }

  return (
    <Fragment>
      <SuccessSnackbar
        open={ui.successMessage.length > 0}
        message={ui.successMessage}
        handleClose={handleSuccessClose}
      />
      <ErrorSnackbar
        open={ui.errorMessage.length > 0}
        message={ui.errorMessage}
        handleClose={handleErrorClose}
      />
    </Fragment>
  );
};

export default DynamicSnackbar;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Snackbar, SnackbarContent, colors } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: colors.blue[600]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(2)
  }
}));

const SuccessSnackbar = props => {
  const {
    open,
    message,
    handleClose
  } = props;

  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      autoHideDuration={6000}
      open={open}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes.content}
        message={
          <span className={classes.message}>
            <CheckCircleIcon className={classes.icon} />
            {message}
          </span>
        }
        variant="h6"
      />
    </Snackbar>
  );
};

SuccessSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string
};

SuccessSnackbar.defaultProps = {
  open: false,
  handleClose: () => {}
};

export default SuccessSnackbar;

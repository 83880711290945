import React, { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import IncidentsTable from './components/IncidentsTable';

import {
  fetchIncidents
} from 'actions';

class IncidentsTableContainer extends Component {
  constructor(props) {
    super(props);
    const {
      page,
      checkId
    } = props;

    this.state = {
      currentPage: page,
      incidentsLoading: true,
      checkId: checkId,
      perPage: 10
    };
  }

  componentDidMount() {
    const {
      checkId,
      perPage
    } = this.state
    const {
      page,
      dataStore,
      sessionStore,
      fetchIncidents
    } = this.props;
    const {
      token
    } = sessionStore;

    const fetchOptions = {
      checkId: checkId,
      perPage: perPage
    };

    let cachedPages = dataStore.incidents.byPage;

    if (checkId) {
      cachedPages = dataStore.selectedCheck.incidents.byPage;
    }

    if (cachedPages[`page${page}`]) {
      this.setState(() => ({
        incidentsLoading: false
      }));
    } else {
      fetchIncidents(token, fetchOptions)
        .then((response) => {
          console.log('[IncidentsTableContainer.js][fetchIncidents]');
          this.setState(() => ({
            incidentsLoading: false
          }));
        })
        .catch((err) => {
          console.err('[IncidentsTableContainer.js][fetchIncidents]: ', err);
        });
    }
  }

  getCheckDetailsLink = (id) => {
    return `/checks/${id}/details`;
  }

  handleChangePage = (_event, pageIndex) => {
    const {
      checkId,
      perPage
    } = this.state;
    const {
      dataStore,
      sessionStore,
      fetchIncidents
    } = this.props;
    const {
      token
    } = sessionStore;

    let cachedPage = dataStore.incidents.byPage;

    if (checkId) {
      cachedPage = dataStore.selectedCheck.incidents.byPage;
    }

    const page = pageIndex + 1;
    const fetchOptions = {
      checkId: checkId,
      page: page,
      perPage: perPage
    };

    if (!cachedPage[`page${page}`]) {
      this.setState(() => ({
        incidentsLoading: true
      }));

      fetchIncidents(token, fetchOptions)
        .then(() => {
          this.setState(() => ({
            currentPage: page,
            incidentsLoading: false
          }));
        })
    } else {
      this.setState(() => ({
        currentPage: page
      }));
    }
  }

  render() {
    const {
      currentPage,
      incidentsLoading,
      checkId,
      perPage
    } = this.state;
    const {
      dataStore
    } = this.props;

    let currentPageIncidents = dataStore.incidents.byPage[`page${currentPage}`];
    let totalIncidentsCount = dataStore.incidents.count;

    if (checkId) {
      currentPageIncidents = dataStore.selectedCheck.incidents.byPage[`page${currentPage}`];
      totalIncidentsCount = dataStore.selectedCheck.incidents.count;
    }

    return (
      <IncidentsTable
        incidents={currentPageIncidents || []}
        currentPage={currentPage}
        rowsPerPage={perPage}
        handleChangePage={this.handleChangePage}
        totalIncidentsCount={totalIncidentsCount}
        loading={incidentsLoading}
        showCheckName={!checkId}
      />
    );
  }
}

IncidentsTableContainer.propTypes = {
  page: PropTypes.number.isRequired
};

IncidentsTableContainer.defaultProps = {
  page: 1
};

const mapStateToProps = (state) => {
  return {
    dataStore: state.dataStore,
    sessionStore: state.sessionStore,
    uiStore: state.uiStore
  };
};

export default connect(mapStateToProps, {
  fetchIncidents: fetchIncidents,
})(IncidentsTableContainer);

import {
  API_BASE_URL
} from './shared';

const defaultOptions = {
  page: 1,
  perPage: 20
};

export const getCheckLogs = (token, userOptions = {}) => {
  // TODO: try bad token to ensure err's caught
  console.log(token);

  const queryOptions = Object.assign({}, defaultOptions, userOptions);

  let queryString = `?page=${queryOptions.page}&per_page=${queryOptions.perPage}`;

  if (queryOptions.checkId) {
    queryString += `&check_id=${queryOptions.checkId}`;
  }

  return fetch(`${API_BASE_URL}/check_logs${queryString}`, {
    headers: {
      'Authorization': `JWT ${token}`
    }
  });
};

export const getIncidents = (token, userOptions = {}) => {
  // TODO: try bad token to ensure err's caught
  console.log(token);

  const queryOptions = Object.assign({}, defaultOptions, userOptions);

  let queryString = `?page=${queryOptions.page}&per_page=${queryOptions.perPage}`;

  if (queryOptions.checkId) {
    queryString += `&check_id=${queryOptions.checkId}`;
  }

  return fetch(`${API_BASE_URL}/incidents${queryString}`, {
    headers: {
      'Authorization': `JWT ${token}`
    }
  });
};

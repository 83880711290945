import * as actionTypes from 'actions';
import {
  connectionsTransformer,
  checksTransformer,
  logsTransformer,
  incidentsTransformer,
  integrationsTransformer,
  findAndUpdateCheck,
  findAndUpdateConnection,
  findAndUpdateIntegration
} from './transformers';

// TODO: REMOVE
console.log('[dataReducer.js]');

const initialState = {
  connections: [],
  checks: [],
  logs: {
    count: 0,
    byPage: {}
  },
  incidents: {
    count: 0,
    byPage: {}
  },
  integrations: [],
  selectedCheck: {
    logs: {
      count: 0,
      byPage: {}
    },
    incidents: {
      count: 0,
      byPage: {}
    }
  }
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_AND_TRANSFORM_DATA: {
      return {
        ...state,
        connections: connectionsTransformer(action.payload),
        checks: checksTransformer(action.payload)
      };
    }

    case actionTypes.LOAD_AND_TRANSFORM_CHECKS: {
      return {
        ...state,
        checks: checksTransformer(action.payload)
      };
    }

    case actionTypes.LOAD_AND_TRANSFORM_CHECK_LOGS: {
      return {
        ...state,
        logs: logsTransformer(state.logs, action.payload)
      };
    }

    case actionTypes.LOAD_AND_TRANSFORM_SELECTED_CHECK_LOGS: {
      return {
        ...state,
        selectedCheck: {
          ...state.selectedCheck,
          logs: logsTransformer(
            state.selectedCheck.logs,
            action.payload
          )
        }
      };
    }

    case actionTypes.LOAD_AND_TRANSFORM_INCIDENTS: {
      return {
        ...state,
        incidents: incidentsTransformer(state.incidents, action.payload)
      };
    }

    case actionTypes.LOAD_AND_TRANSFORM_SELECTED_CHECK_INCIDENTS: {
      return {
        ...state,
        selectedCheck: {
          ...state.selectedCheck,
          incidents: incidentsTransformer(
            state.selectedCheck.incidents,
            action.payload
          )
        }
      };
    }

    case actionTypes.LOAD_AND_TRANSFORM_INTEGRATIONS: {
      return {
        ...state,
        integrations: integrationsTransformer(action.payload)
      };
    }

    case actionTypes.RESET_SELECTED_CHECK: {
      return {
        ...state,
        selectedCheck: {
          logs: {
            count: 0,
            byPage: {}
          },
          incidents: {
            count: 0,
            byPage: {}
          }
        }
      };
    }

    case actionTypes.SET_CHECK_STATUS: {
      return {
        ...state,
        checks: findAndUpdateCheck(state.checks, action.payload)
      }
    }

    case actionTypes.SET_CONNECTION_STATUS: {
      return {
        ...state,
        connections: findAndUpdateConnection(state.connections, action.payload)
      }
    }

    case actionTypes.SET_INTEGRATION_STATUS: {
      return {
        ...state,
        integrations: findAndUpdateIntegration(state.integrations, action.payload)
      }
    }

    default: {
      return state;
    }
  }
};

export default dataReducer;

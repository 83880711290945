import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

function Modal({
  open,
  submitting,
  closeHandler,
  confirmHandler,
  title,
  message
}) {
  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeHandler}
          disabled={submitting}
        >
          Close
        </Button>
        <Button
          onClick={confirmHandler} color="primary" autoFocus
          disabled={submitting}
        >
          {submitting
            ? (<span>Processing...</span>)
            : (<span>Confirm</span>)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal;

import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Collapse,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  colors
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Skeleton } from '@material-ui/lab';
import { Label } from 'components';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0
  },
  checkLink: {
    color: '#078BA2',
    fontSize: 14,
    textTransform: 'uppercase'
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  noIncidentLabelWrapper: {
    borderBottom: 0,
    width: 200
  },
  noIncidentLabel: {
    margin: '16px 16px 0'
  },
  notificationTypeLabel: {
    width: 80
  },
  collapsedCellWrapper: {
    paddingTop: 0,
    paddingBottom: 0
  },
  idCellWrapper: {
    position: 'relative',
    paddingLeft: 42
  },
  rowActionArrowIcon: {
    position: 'absolute',
    top: 15,
    left: 10
  },
  collapsibleRow: {
    cursor: 'pointer'
  },
  skeletonRowWrapper: {
    '& .MuiTableCell-root': {
      padding: 12
    }
  },
  collapsibleRowWrapper: {
    '& .MuiTableCell-root': {
      borderBottom: 0
    }
  }
}));

const IncidentsTable = ({
  incidents,
  totalIncidentsCount,
  currentPage,
  rowsPerPage,
  loading,
  handleChangePage,
  showCheckName
}) => {
  const classes = useStyles();
  const [collapsedRow, setCollapsedRow] = useState(null);

  const statusColors = {
    email: colors.cyan[500],
    slack: colors.deepPurple[500],
    webhook: colors.deepOrange[500],
    pagerduty: colors.green['A700']
  };

  const getCheckDetailsLink = (id) => {
    return `/checks/${id}/details`;
  };

  const renderArrowIcon = (index) => {
    if (collapsedRow === index) {
      return (
        <KeyboardArrowDownIcon
          className={classes.rowActionArrowIcon}
        />
      );
    } else {
      return (
        <KeyboardArrowRightIcon
          className={classes.rowActionArrowIcon}
        />
      );
    }
  };

  const handleRowClick = (index) => {
    if (collapsedRow === index) {
      setCollapsedRow(null);
    } else {
      setCollapsedRow(index);
    }
  };

  if (loading) {
    return (
      <Card>
        <CardHeader title="Loading Incidents..." />
        <Divider />
        <CardContent className={classes.content}>
          <Table>
            <TableHead>
              {[...Array(rowsPerPage).keys()].map((item) => (
                <TableRow
                  key={item}
                  className={classes.skeletonRowWrapper}
                >
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  {showCheckName
                    ? (
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      )
                    : null}
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
            </TableHead>
          </Table>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader title="Recent Incidents" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Date</TableCell>
              {showCheckName
                ?
                  (
                    <TableCell>Check</TableCell>
                  )
                : null}
              <TableCell>Type</TableCell>
              <TableCell>Notification</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {incidents.map((incident, index) => (
                <Fragment
                    key={incident.id}
                >
                  <TableRow
                    onClick={() => handleRowClick(index)}
                    className={incident.additionalNotifications.length ? classes.collapsibleRow : null}
                  >
                    <TableCell
                      className={classes.idCellWrapper}
                    >
                      {incident.additionalNotifications.length
                        ? renderArrowIcon(index)
                        : null}
                      {incident.id}
                    </TableCell>
                    <TableCell>{incident.createdAt}</TableCell>
                    {showCheckName
                      ?
                        (
                          <TableCell>
                            <Link
                              color="textPrimary"
                              component={RouterLink}
                              noWrap
                              to={getCheckDetailsLink(incident.checkId)}
                              variant="h5"
                              className={classes.checkLink}
                            >
                              {incident.checkName}
                            </Link>
                          </TableCell>
                        )
                      : null}
                    <TableCell>
                      <Label
                        color={statusColors[incident.notificationTypeName]}
                        variant="outlined"
                        className={classes.notificationTypeLabel}
                      >
                        {incident.notificationTypeName}
                      </Label>
                    </TableCell>
                    <TableCell>{incident.message}</TableCell>
                  </TableRow>
                  {incident.additionalNotifications.map((notification) => (
                    <TableRow
                      key={notification.id}
                      className={collapsedRow !== index ? classes.collapsibleRowWrapper : null}
                    >
                      <TableCell
                        colSpan={showCheckName ? 3 : 2}
                        className={collapsedRow !== index ? classes.collapsedCellWrapper : null}
                      >
                        <Collapse
                          in={collapsedRow === index}
                          timeout="auto"
                          unmountOnExit
                        >
                        </Collapse>
                      </TableCell>
                      <TableCell
                        className={collapsedRow !== index ? classes.collapsedCellWrapper : null}
                      >
                        <Collapse
                          in={collapsedRow === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Label
                            color={statusColors[notification.notificationTypeName]}
                            variant="outlined"
                            className={classes.notificationTypeLabel}
                          >
                            {notification.notificationTypeName}
                          </Label>
                        </Collapse>
                      </TableCell>
                      <TableCell
                        className={collapsedRow !== index ? classes.collapsedCellWrapper : null}
                      >
                        <Collapse
                          in={collapsedRow === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          {notification.message}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  ))}
              </Fragment>
            ))}
            {incidents.length < 1
              ? (
                  <TableRow>
                    <TableCell
                      classes={{
                        root: classes.noIncidentLabelWrapper
                      }}
                    >
                      <Label
                        color={colors.green[600]}
                        className={classes.noIncidentLabel}
                      >
                        No Incidents
                      </Label>
                    </TableCell>
                  </TableRow>
                )
              : null}
          </TableBody>
        </Table>
      </CardContent>
      {incidents.length > 1
        ?
          (
            <CardActions className={classes.actions}>
              <TablePagination
                component="div"
                count={totalIncidentsCount}
                onChangePage={handleChangePage}
                page={currentPage - 1}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
              />
            </CardActions>
          )
        : null}
    </Card>
  );
};

IncidentsTable.propTypes = {
  className: PropTypes.string,
  showCheckName: PropTypes.bool.isRequired
};

IncidentsTable.defaultProps = {
  showCheckName: false
};

export default IncidentsTable;

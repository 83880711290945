// TODO: REMOVE ONCE DONE, disabling warnings while WIP
/* eslint-disable */

import {
  API_BASE_URL
} from './shared';

import {
  doesErrorExist
} from 'actions/utilities/errorHandling';

import {
  mungeToCheckForm,
  transformDataForCheckPost
} from './helpers';

export const getChecksList = (token) => {
  // TODO: try bad token to ensure err's caught
  console.log(token);

  return fetch(`${API_BASE_URL}/checks`, {
    headers: {
      'Authorization': `JWT ${token}`
    }
  });
};

export const getCheck = (token, id) => {
  return fetch(`${API_BASE_URL}/munge/checks?id=${id}`, {
    headers: {
      'Authorization': `JWT ${token}`
    }
  })
    .then((res) => res.json())
    .then((data) => {
      if (doesErrorExist(data)) {
        throw data;
      }

      return mungeToCheckForm(data.check);
    })
    .catch((err) => {
      throw err;
    });
};

export const updateExistingCheck = async (token, values, id) => {
  try {
    // TODO: do this for create / others
    const transformedCheckFormData = transformDataForCheckPost(values);

    // TODO: IMPLEMENT
    const verifyCheckResult = await verifyCheck(token, transformedCheckFormData);

    if (doesErrorExist(verifyCheckResult)) {
      throw verifyCheckResult;
    }

    // TODO: REMOVE
    console.log(verifyCheckResult);

    // TODO: REMOVE
    console.log('[updateExistingCheck]: verifyCheck() - success');

    const updateCheckResult = await updateCheck(token, transformedCheckFormData, id);

    if (doesErrorExist(updateCheckResult)) {
      throw updateCheckResult;
    }

    // TODO: REMOVE
    console.log('[updateExistingCheck]: updateCheck() - success')

    // TODO: REMOVE
    console.log(updateCheckResult);

    return updateCheckResult;
  } catch(err) {
    throw err;
  }
};

export const createNewCheck = async (token, values) => {
  try {
    // TODO: do this for create / others
    const transformedCheckFormData = transformDataForCheckPost(values);

    // TODO: REMOVE
    console.log(transformedCheckFormData);

    // TODO: IMPLEMENT
    const verifyCheckResult = await verifyCheck(token, transformedCheckFormData);

    if (doesErrorExist(verifyCheckResult)) {
      throw verifyCheckResult;
    }

    // TODO: REMOVE
    console.log(verifyCheckResult);

    // TODO: REMOVE
    console.log('[createNewCheck]: verifyCheck() - success');

    const createCheckResult = await createCheck(token, transformedCheckFormData);

    if (doesErrorExist(createCheckResult)) {
      throw createCheckResult;
    }

    // TODO: REMOVE
    console.log('[createNewCheck]: createCheck() - success')

    // TODO: REMOVE
    console.log(createCheckResult);

    return createCheckResult;
  } catch(err) {
    throw err;
  }
};

function verifyCheck(token, transformedCheckFormData) {
  // TODO: switch to debugger level logging / add global flag
  console.group('verifyCheck()');
  console.log('transformedCheckFormData', transformedCheckFormData);
  console.groupEnd();

  return fetch(`${API_BASE_URL}/munge/verify_check`, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(transformedCheckFormData)
  })
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
};

function createCheck(token, transformedCheckFormData) {
  // TODO: switch to debugger level logging / add global flag
  console.group('createCheck()');
  console.log('transformedCheckFormData', transformedCheckFormData);
  console.groupEnd();

  return fetch(`${API_BASE_URL}/munge/checks`, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(transformedCheckFormData)
  })
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
};

function updateCheck(token, transformedCheckFormData, id) {
  // TODO: switch to debugger level logging / add global flag
  console.group('updateCheck()');
  console.log('transformedCheckFormData', transformedCheckFormData);
  console.groupEnd();

  transformedCheckFormData.id = id;
  // !FIXME: don't hardcode, need to add toggles in UI
  transformedCheckFormData.status = 'active';

  return fetch(`${API_BASE_URL}/munge/checks`, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(transformedCheckFormData)
  })
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
};

export const updateCheckStatus = (token, id, status) => {
  // TODO: switch to debugger level logging / add global flag
  console.group('updateCheckStatus()');
  console.log(`Setting check: ${id} to ${status}`);
  console.groupEnd();

  const postBody = {
    id: id,
    status: status
  };

  return fetch(`${API_BASE_URL}/checks`, {
    method: 'POST',
    headers: {
      'Authorization': `JWT ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(postBody)
  })
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
};

export default {
  // Check --- SubForm
  checkName: '',
  connectionId: null,
  connectionTypeId: null,
  checkType: 1, // Freshness / Equal Expression / Threshold / Custom?

  // Data Source --- SubForm
  dataSource: {
    freshness: {
        select: '',
        from: {
          database: '',
          schema: '',
          table: ''
        },
        where: '',
        groupBy: ''
    },
    threshold: {
      select: '',
      from: {
        database: '',
        schema: '',
        table: ''
      },
      where: '',
      groupBy: '',
      thresholdValue: '',
      comparisonOperator: '>' // >, >=, <, <=, <
    },
    assert: {
      query: '' // this will just be a freeform query that will have to return a 1 or 0 (basically an assert), I also called this an assert test
    },
    crossDataSourceEqualExpression: {
      tolerance: "0",
      tableA: {
        connectionId: null,
        connectionTypeId: null,
        select: '',
        from: {
          database: '',
          schema: '',
          table: ''
        },
        where: '',
        groupBy: ''
      },
      tableB: {
        connectionId: null,
        connectionTypeId: null,
        select: '',
        from: {
          database: '',
          schema: '',
          table: ''
        },
        where: '',
        groupBy: ''
      }
    },
  },

  // Schedule --- SubForm
  schedule: {
    runMapValue: 4,
    failCount: 2
  },

  // Notification --- SubForm
  notifications: [
    {
      typeId: 1,
      name: '',
      email: '',
      webhook: {
        method: 'post',
        url: ''
      },
      slackChannel: '',
      status: 'active'
    }
  ],
  notificationMessage: '',

  // Miscellaneous
  slackApiKey: null,
  pagerdutyApiKey: null
};

// "email": "foo@foo.com", 
// "id": 11, 
// "message": "", 
// "name": "webhook it", 
// "slack_channel": null, 
// "status": "active", 
// "type_id": 1, 
// "webhook_method": null, 
// "webhook_url": null

export const SET_SUCCESS_SNACKBAR_MESSAGE = 'SET_SUCCESS_SNACKBAR_MESSAGE';
export const SET_ERROR_SNACKBAR_MESSAGE = 'SET_ERROR_SNACKBAR_MESSAGE';

export const SET_USER_DATA_FETCH_STATUS_LOADING = 'SET_USER_DATA_FETCH_STATUS_LOADING';
export const SET_USER_DATA_FETCH_STATUS_SUCCESS = 'SET_USER_DATA_FETCH_STATUS_SUCCESS';
export const SET_USER_DATA_FETCH_STATUS_FAILURE = 'SET_USER_DATA_FETCH_STATUS_FAILURE';

export const SET_USER_INCIDENTS_FETCH_STATUS_LOADING = 'SET_USER_INCIDENTS_FETCH_STATUS_LOADING';
export const SET_USER_INCIDENTS_FETCH_STATUS_SUCCESS = 'SET_USER_INCIDENTS_FETCH_STATUS_SUCCESS';
export const SET_USER_INCIDENTS_FETCH_STATUS_FAILURE = 'SET_USER_INCIDENTS_FETCH_STATUS_FAILURE';

export const SET_USER_LOGS_FETCH_STATUS_LOADING = 'SET_USER_LOGS_FETCH_STATUS_LOADING';
export const SET_USER_LOGS_FETCH_STATUS_SUCCESS = 'SET_USER_LOGS_FETCH_STATUS_SUCCESS';
export const SET_USER_LOGS_FETCH_STATUS_FAILURE = 'SET_USER_LOGS_FETCH_STATUS_FAILURE';

export const setSuccessSnackbarMessage = (message) => (
  {
    type: SET_SUCCESS_SNACKBAR_MESSAGE,
    payload: message
  }
);

export const setErrorSnackbarMessage = (message) => (
  {
    type: SET_ERROR_SNACKBAR_MESSAGE,
    payload: message
  }
);

export const setUserDataFetchStatusLoading = (message) => (
  {
    type: SET_USER_DATA_FETCH_STATUS_LOADING
  }
);

export const setUserDataFetchStatusSuccess = (message) => (
  {
    type: SET_USER_DATA_FETCH_STATUS_SUCCESS
  }
);

export const setUserDataFetchStatusFailure = (message) => (
  {
    type: SET_USER_DATA_FETCH_STATUS_FAILURE
  }
);

export const setUserIncidentsFetchStatusLoading = (message) => (
  {
    type: SET_USER_INCIDENTS_FETCH_STATUS_LOADING
  }
);

export const setUserIncidentsFetchStatusSuccess = (message) => (
  {
    type: SET_USER_INCIDENTS_FETCH_STATUS_SUCCESS
  }
);

export const setUserIncidentsFetchStatusFailure = (message) => (
  {
    type: SET_USER_INCIDENTS_FETCH_STATUS_FAILURE
  }
);

export const setUserLogsFetchStatusLoading = (message) => (
  {
    type: SET_USER_LOGS_FETCH_STATUS_LOADING
  }
);

export const setUserLogsFetchStatusSuccess = (message) => (
  {
    type: SET_USER_LOGS_FETCH_STATUS_SUCCESS
  }
);

export const setUserLogsFetchStatusFailure = (message) => (
  {
    type: SET_USER_LOGS_FETCH_STATUS_FAILURE
  }
);


import {
  getConnectionsList
} from 'lib/services/connectionService';
import {
  getChecksList
} from 'lib/services/checkService';
import {
  getCheckLogs,
  getIncidents
} from 'lib/services/checkLogService';

import {
  getIntegrationsList
} from 'lib/services/integrationService';

import {
  doesErrorExist,
  generateErrorMessage
} from './utilities/errorHandling';

import {
  toggleSlackIntegration,
  togglePagerdutyIntegration,
  setUserDataFetchStatusLoading,
  setUserDataFetchStatusSuccess,
  setUserDataFetchStatusFailure,
  setUserIncidentsFetchStatusLoading,
  setUserIncidentsFetchStatusSuccess,
  setUserIncidentsFetchStatusFailure,
  setUserLogsFetchStatusLoading,
  setUserLogsFetchStatusSuccess,
  setUserLogsFetchStatusFailure
} from 'actions';

export const LOAD_AND_TRANSFORM_DATA = 'LOAD_AND_TRANSFORM_DATA';
export const LOAD_AND_TRANSFORM_CHECKS = 'LOAD_AND_TRANSFORM_CHECKS';
export const LOAD_AND_TRANSFORM_CHECK_LOGS = 'LOAD_AND_TRANSFORM_CHECK_LOGS';
export const LOAD_AND_TRANSFORM_SELECTED_CHECK_LOGS = 'LOAD_AND_TRANSFORM_SELECTED_CHECK_LOGS';
export const LOAD_AND_TRANSFORM_INCIDENTS = 'LOAD_AND_TRANSFORM_INCIDENTS';
export const LOAD_AND_TRANSFORM_SELECTED_CHECK_INCIDENTS = 'LOAD_AND_TRANSFORM_SELECTED_CHECK_INCIDENTS';
export const LOAD_AND_TRANSFORM_INTEGRATIONS = 'LOAD_AND_TRANSFORM_INTEGRATIONS';
export const RESET_SELECTED_CHECK = 'RESET_SELECTED_CHECK';
export const SET_CHECK_STATUS = 'SET_CHECK_STATUS';
export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS';
export const SET_INTEGRATION_STATUS = 'SET_INTEGRATION_STATUS';

export const loadAndTransformChecks = (objectToTransform) => (
  {
    type: LOAD_AND_TRANSFORM_CHECKS,
    payload: objectToTransform
  }
);

export const loadAndTransformData = (objectToTransform) => (
  {
    type: LOAD_AND_TRANSFORM_DATA,
    payload: objectToTransform
  }
);

export const loadAndTransformCheckLogs = (objectToTransform) => (
  {
    type: LOAD_AND_TRANSFORM_CHECK_LOGS,
    payload: objectToTransform
  }
);

export const loadAndTransformSelectedCheckLogs = (objectToTransform) => (
  {
    type: LOAD_AND_TRANSFORM_SELECTED_CHECK_LOGS,
    payload: objectToTransform
  }
);

export const loadAndTransformIncidents = (objectToTransform) => (
  {
    type: LOAD_AND_TRANSFORM_INCIDENTS,
    payload: objectToTransform
  }
);

export const loadAndTransformSelectedCheckIncidents = (objectToTransform) => (
  {
    type: LOAD_AND_TRANSFORM_SELECTED_CHECK_INCIDENTS,
    payload: objectToTransform
  }
);

export const loadAndTransformIntegrations = (objectToTransform) => (
  {
    type: LOAD_AND_TRANSFORM_INTEGRATIONS,
    payload: objectToTransform
  }
);

export const resetSelectedCheck = () => (
  {
    type: RESET_SELECTED_CHECK
  }
);

export const setCheckStatus = ({ check }) => ({
  type: SET_CHECK_STATUS,
  payload: check
});

export const setConnectionStatus = ({ connection }) => ({
  type: SET_CONNECTION_STATUS,
  payload: connection
});

export const setIntegrationStatus = ({ integration }) => ({
  type: SET_INTEGRATION_STATUS,
  payload: integration
});

export const fetchUserDataStore = (token) => {
  return async (dispatch) => {
    try {
      dispatch(setUserDataFetchStatusLoading());
      dispatch(fetchUserIntegrations(token));

      const [
        connectionsResult,
        checksResult
      ] = await Promise.all([
          getConnectionsList(token),
          getChecksList(token)
        ])
        .then((responses) => {
          return Promise.all(responses.map((res) => res.json()));
        })
        .then((dataObjects) => {
          dataObjects.forEach((data) => {
            if (doesErrorExist(data)) {
              throw data;
            };
          });

          return dataObjects;
        });

      // TODO: REMOVE
      console.group('[fetchUserDataStore]');
      console.log('connectionsResult: ', connectionsResult);
      console.log('checksResult: ', checksResult);
      console.groupEnd();

      const dataToTransform = {
        connectionsData: connectionsResult,
        checksList: checksResult.checks
      };

      dispatch(loadAndTransformData(dataToTransform));

      // next tick / after transform has happened
      setTimeout(() => {
        dispatch(setUserDataFetchStatusSuccess());
      }, 500);
    } catch(err) {
      const errorMessage = generateErrorMessage(err);

      dispatch(setUserDataFetchStatusFailure());
      console.error(`[fetchUserDataStore]: ${errorMessage}`);
    }
  }
};

export const fetchChecks = (token) => {
  return (dispatch) => {
    return getChecksList(token)
      .then((res) => res.json())
      .then((data) => {
        if (doesErrorExist(data)) {
          throw data;
        }

        // TODO: REMOVE
        console.group('[fetchChecks]');
        console.log('checksResult: ', data);
        console.groupEnd();

        const dataToTransform = {
          checksList: data.checks
        };

        dispatch(loadAndTransformChecks(dataToTransform));
      })
      .catch(({ error, description }) => {
        console.error(`ERR: ${error}, DESC: ${description}`);
      });
  }
};

export const fetchCheckLogs = (token, userOptions = {}) => {
  return (dispatch) => {
    dispatch(setUserLogsFetchStatusLoading());

    return getCheckLogs(token, userOptions)
      .then((res) => res.json())
      .then((data) => {
        if (doesErrorExist(data)) {
          throw data;
        }

        data.page = userOptions.page || 1;

        // TODO: REMOVE
        console.group('[fetchCheckLogs]');
        console.log('checkLogResult: ', data);
        console.groupEnd();

        if (userOptions.checkId) {
          dispatch(loadAndTransformSelectedCheckLogs(data));
        } else {
          dispatch(loadAndTransformCheckLogs(data));
        }

        // next tick / after transform has happened
        setTimeout(() => {
          dispatch(setUserLogsFetchStatusSuccess());
        }, 500);

        return data.check_logs;
      })
      .catch(({ error, description }) => {
        dispatch(setUserLogsFetchStatusFailure());

        console.error(`ERR: ${error}, DESC: ${description}`);
      });
  }
};

export const fetchIncidents = (token, userOptions = {}) => {
  return (dispatch) => {
    dispatch(setUserIncidentsFetchStatusLoading());

    return getIncidents(token, userOptions)
      .then((res) => res.json())
      .then((data) => {
        if (doesErrorExist(data)) {
          throw data;
        }

        data.page = userOptions.page || 1;

        // TODO: REMOVE
        console.group('[fetchIncidents]');
        console.log('incidentResult: ', data);
        console.groupEnd();

        if (userOptions.checkId) {
          dispatch(loadAndTransformSelectedCheckIncidents(data));
        } else {
          dispatch(loadAndTransformIncidents(data));
        }

        // TODO: transform incidents ==> dispatch ==> THEN return data
        // return data.incidents;

        setTimeout(() => {
          dispatch(setUserIncidentsFetchStatusSuccess());
        }, 3000);
      })
      .catch(({ error, description }) => {
        dispatch(setUserIncidentsFetchStatusFailure());

        console.error(`ERR: ${error}, DESC: ${description}`);
      });
  }
};


export const fetchUserIntegrations = (token) => {
  return (dispatch) => {
    getIntegrationsList(token)
      .then(
        (data) => {
          // TODO: need to standardize responses
          if (doesErrorExist(data)) {
            throw data;
          }

          // TODO: REMOVE
          console.group('[fetchUserIntegrations]');
          console.log('integrationResult: ', data);
          console.groupEnd();

          dispatch(loadAndTransformIntegrations(data.integrations));
          // TODO: combine integrations checks
          dispatch(toggleSlackIntegration(data.integrations));
          dispatch(togglePagerdutyIntegration(data.integrations));
        },
        (err) => {
          console.error('[fetchUserIntegrations][getIntegrationsList]:', err);
        }
      )
      .catch(({ error, description }) => {
        console.error(`ERR: ${error}, DESC: ${description}`);
      });
  }
};


import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Link,
  colors
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  ButtonCopyToClipboard,
  Label
} from 'components';

// TODO: import only what's needed from hljs, e.g. sql, etc.
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  checkLink: {
    color: '#078BA2',
    fontSize: 14,
    textTransform: 'uppercase'
  },
  tooltipContainer: {
    width: 500
  },
  tooltipQueryWrapper: {
    background: `${theme.palette.white} !important`,
    width: '100%',
    fontSize: 14
  },
  queryWrapper: {
    // !important to override hljs
    background: `${theme.palette.white} !important`,
    width: 300,
    height: 68,
    userSelect: 'none',
    overflow: 'hidden'
  },
  tooltipCell: {
    position: 'relative',
    "&:hover": {
      textAlign: 'center',
      "&::before": {
        display: 'none'
      }
    },
    "&::before": {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 300,
      height: '75%',
      bottom: 0,
      background: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)) left repeat',
      pointerEvents: 'none'
    }
  },
  tooltipCellRowHover: {
    "&::before": {
      background: 'linear-gradient(rgba(255, 255, 255, 0), rgba(244, 246, 248, 1)) left repeat'
    },
    "& pre": {
      background: '#F4F6F8 !important'
    }
  },
  noLogsLabelWrapper: {
    borderBottom: 0,
    width: 200
  },
  noLogsLabel: {
    margin: '16px 16px 0'
  }
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    maxWidth: 500,
    padding: 0
  }
}))(Tooltip);

const LogsTable = ({
  logs,
  totalLogsCount,
  currentPage,
  rowsPerPage,
  loading,
  handleChangePage
}) => {
  const classes = useStyles();
  const [copyButtonVisible, setCopyButtonVisible] = useState(null);
  const [hoverRowId, setHoverRowId] = useState(null);

  const showCopyButton = (id) => {
    setCopyButtonVisible(id)
  };

  const hideCopyButton = () => {
    setCopyButtonVisible(null)
  };

  const toggleCopyButton = (id) => {
    if (!copyButtonVisible) {
      setCopyButtonVisible(id);
    } else {
      setCopyButtonVisible(null);
    }
  }

  const toggleHoverState = (id) => {
    if (hoverRowId !== id) {
      setCopyButtonVisible(null);
    }

    setHoverRowId(id)
  };

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      hljs.highlightBlock(node);
    }
  }, []);

  const getCheckDetailsLink = (id) => {
    return `/checks/${id}/details`;
  };

  if (loading) {
    return (
      <Card>
        <CardHeader title="Loading Logs..." />
        <Divider />
        <CardContent className={classes.content}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader title="Recent Logs" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Data Source</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Check</TableCell>
                  <TableCell>Query</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((log) => (
                  <TableRow
                    hover
                    key={log.id}
                    onMouseEnter={() => toggleHoverState(log.id)}
                    onMouseLeave={() => setHoverRowId(null)}
                    onClick={() => toggleCopyButton(log.id)}
                  >
                    <TableCell>{log.id}</TableCell>
                    <TableCell>{log.date}</TableCell>
                    <TableCell>{log.connectionName}</TableCell>
                    <TableCell>{log.checkTypeName}</TableCell>
                    <TableCell>
                      <Link
                        color="textPrimary"
                        component={RouterLink}
                        noWrap
                        to={getCheckDetailsLink(log.checkId)}
                        variant="h5"
                        className={classes.checkLink}
                      >
                        {log.checkName}
                      </Link>
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.tooltipCell,
                        hoverRowId === log.id && classes.tooltipCellRowHover
                      )}
                      onMouseEnter={() => showCopyButton(log.id)}
                      onMouseLeave={() => hideCopyButton()}
                    >
                      {copyButtonVisible === log.id
                        ? (
                            <HtmlTooltip
                              placement="bottom-end"
                              open={copyButtonVisible === log.id}
                              title={
                                <pre
                                  ref={measuredRef}
                                  className={classes.tooltipQueryWrapper}
                                >
                                  <code>
                                    {log.query}
                                  </code>
                                </pre>
                              }
                            >
                              <ButtonCopyToClipboard
                                text={log.query}
                              />
                            </HtmlTooltip>
                          )
                        : (
                              <pre
                                className={classes.queryWrapper}
                                ref={measuredRef}
                              >
                                <code>
                                  {log.query}
                                </code>
                              </pre>
                          )
                      }
                    </TableCell>
                    <TableCell>{log.value}</TableCell>
                  </TableRow>
                ))}
                {logs.length < 1
                  ? (
                      <TableRow>
                        <TableCell
                          classes={{
                            root: classes.noLogsLabelWrapper
                          }}
                        >
                          <Label
                            color={colors.green[600]}
                            className={classes.noLogsLabel}
                          >
                            No Logs
                          </Label>
                        </TableCell>
                      </TableRow>
                    )
                  : null}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      {logs.length > 1
        ?
          (
            <CardActions className={classes.actions}>
              <TablePagination
                component="div"
                count={totalLogsCount}
                onChangePage={handleChangePage}
                page={currentPage - 1}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
              />
            </CardActions>
          )
        : null}
    </Card>
  );
};

LogsTable.propTypes = {
  className: PropTypes.string,
};

LogsTable.defaultProps = {
  logs: []
};

export default LogsTable;
